/* First lets set a background */
.bg{
  position: fixed;
  top: 1%;
  left: 1%;
  width: 45px;
  height: 45px;
  background: url(../images/sheep_transp.png);
  background-position: center center;
  background-origin: content-box;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3;
}

/* Now the same background, but with brightness filters*/
.lightning{
  position: fixed;
  top: 1%;
  left: 1%;
  width: 45px;
  height: 45px;
  background: url(../images/sheep_transp.png);
  background-position: center center;
  background-origin: content-box;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-filter: brightness(3);
  filter: brightness(3);
  -o-filter: brightness(3);
  -moz-filter: brightness(3);
  z-index: 4;
}

/*Now just a opacity animation*/
.flashit{
  -webkit-animation: flash ease-out 7s infinite;
  -moz-animation: flash ease-out 7s infinite;
  animation: flash ease-out 7s infinite;
  animation-delay: 2s;
}

@-webkit-keyframes flash {
  from { opacity: 0; }
  92% { opacity: 0; }
  93% { opacity: 0.6; }
  94% { opacity: 0.2; }
  96% { opacity: 0.9; }
  to { opacity: 0; }
}

@keyframes flash {
  from { opacity: 0; }
  92% { opacity: 0; }
  93% { opacity: 0.6; }
  94% { opacity: 0.2; }
  96% { opacity: 1; }
  to { opacity: 0; }
}
/*End! is that simple! */
