@import "~bootstrap/scss/bootstrap";
@import "./flashing_logo.scss";
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

//body {
//  background-color: #222;
//}

.hidden {
  display: none;
}


body {
  font-family: "Montserrat";
}

.header-jumbotron {
  background-size: 2000px;
  background-image: url(../images/jumbotron-header-bg-min.jpeg)
}

.developer-text {
  font-family: 'Inconsolata', monospace;
}

.jasiek-saying-hi {
  margin-bottom: 2em;
}

.toast-body {
  background-color: rgba(255, 255, 255, 1)
}

.section-title h1::before, .section-title h1::after {
  margin: 15px 15px 10px 15px;
  content: '';
  width: 50px;
  height: 2px;
  background: #222;
  display: inline-block;
}

.services-section {
  margin-bottom: 2em;
}

#root > div.container:nth-child(3) > hr {
  margin-bottom: 3.5em;
}

.design-section {
  background-image: url(../images/design-section-bg-min.jpeg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-bottom:10%;
}